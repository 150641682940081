import React from "react";

const ModalFrame = ({ children }) => {
   return (
      <div
         role="status"
         className="absolute inset-0 z-30 flex h-full min-w-xs items-center justify-center bg-gray-700 bg-opacity-60"
      >
         {children}
      </div>
   );
};

export default ModalFrame;
