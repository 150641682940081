/**
 * @author seancabalse
 * @email seancabalse.dev@gmail.com
 * @create date 2023-06-27 10:03:34
 * @modify date 2023-06-27 10:05:09
 * @desc This file contains all the utility functions for the setting
 * delay and asynchronosity of the application.
 *
 */

/**
 * This function delays the specified amount of time
 * @param {Number} ms - Milliseconds to delay
 * @returns Promise that resolves after the specified amount of time
 */
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export { delay };
