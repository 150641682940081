import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ChevronLeftSVG from "../assets/chevron-left.svg";
import ChevronRightSVG from "../assets/chevron-right.svg";
import EditSVG from "../assets/edit-icon.svg";
import GCashLogo from "../assets/gcash-logo.svg";

import MobileNumberField from "../components/MobileNumberField";
import ModalFrame from "../components/ModalFrame";
import WholeScreenLoading from "../components/WholeScreenLoading";
import { PREPAID_PROMOS_DATA } from "../data/promoData";
import useBrandedMobileNumber from "../hooks/useBrandedMobileNumber";
import { getCache, removeCache, setCache } from "../services/cache";
import {
   cxsCreatePaymentSession,
   cxsGetPaymentSessionByTokenPaymentId,
} from "../services/cxs";
import { checkAndFormatNumber } from "../utils/formatNumber";
import { delay } from "../services/delay";
import {
   checkForDefaultExtras,
   getNfMainParam,
   getNfMainServiceId,
   sortFreebiesById,
} from "../utils/promoUtils";
import { GoRENEWmodal, TermsAndConditions } from "../components/GoRENEW";
import { useError } from "../providers/Error";

// Uncomment imports below for search and catalog function

import FilterSVG from "../assets/filter-icon.svg";
import SearchSVG from "../assets/search-icon.svg";
import CarouselCatalog from "../components/CarouselCatalog";
import ErrorModal from "../components/ErrorModal";
import { useBoosters } from "../providers/Booster";

const TopSection = () => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   return (
      <>
         <div className="z-10 w-full max-w-xl bg-accent-light px-4 py-3 shadow-lg">
            <div className="flex items-center justify-between py-2.5">
               <img
                  src={ChevronLeftSVG}
                  onClick={() =>
                     navigate({
                        pathname: "/",
                        search: searchParams.toString(),
                     })
                  }
                  className="cursor-pointer"
                  alt="Go Back"
               />

               <p className="font-bold uppercase tracking-widest text-accent-dark">
                  Buy Globe Prepaid
               </p>

               <div></div>
            </div>

            {/* search section */}
            <div className="flex hidden items-center justify-between rounded bg-white">
               <div className="border-r border-r-gray-100 px-3 py-3">
                  <img src={SearchSVG} alt="Search" />
               </div>

               <div className="relative w-full flex-1">
                  <input
                     type="text"
                     className="w-full px-4 py-1.5 focus:rounded focus:border-2 focus:border-primary focus:outline-none"
                     placeholder="What are you looking for?"
                  />
               </div>

               <div className="border-l border-l-gray-100 px-3 py-3">
                  <img src={FilterSVG} alt="Filter promos" />
               </div>
            </div>
            {/* endof search section */}
         </div>
         <CarouselCatalog />
      </>
   );
};

const BottomPanel = ({
   extra,
   nfParam,
   price,
   mobileNumber,
   setIsChangingMobileNumber,
   fnLoading,
   fnError,
   fnErrorData,
   selectedCatalog,
}) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const [retryCount, setRetryCount] = useState(0);

   const onlyHasDefaults = selectedCatalog.id
      ? checkForDefaultExtras(selectedCatalog.id)
      : false;
   const localEFreebie = getCache("entertainment");
   const localLsFreebie = getCache("lifestyle");

   const { selectedBoosters } = useBoosters();
   let boosterTotal = 0;

   if (
      selectedBoosters.length > 0 &&
      onlyHasDefaults &&
      selectedCatalog.price !== 350 &&
      selectedCatalog.price !== 90
   ) {
      boosterTotal = selectedBoosters
         .map((record) => record.amount)
         .reduce((a, b) => a + b);
   } else {
      boosterTotal = "0";
   }

   if (onlyHasDefaults) {
      removeCache("ng1_bcf_nf_service_id");
      removeCache("entertainment");
      removeCache("lifestyle");
   }

   const getTotalPrice = () => {
      return parseInt(price) + parseInt(boosterTotal);
   };

   /**
    * This function will determine if subscribe button will enable/disable
    *
    * @returns {boolean}
    */
   const isSubscribeBtnEnabled = () => {
      return (
         (nfParam && onlyHasDefaults) ||
         (!onlyHasDefaults && localEFreebie && localLsFreebie)
      );
   };

   /**
    * This function is triggered when subscribe button is clicked.
    */
   const subscribeToPromo = async () => {
      let errorName;

      /**
       * This method recursively calls the GetPaymentSessionByTokenPaymentId
       * API to get the payment session details until the status is either
       * GCASH_AUTHORIZED or GCASH_REFUSED
       * @param {String} tokenPaymentId - Create payment session Id
       */
      async function retrieveSessionDetails(tokenPaymentId) {
         let paymentSession;

         try {
            paymentSession = await cxsGetPaymentSessionByTokenPaymentId(
               tokenPaymentId
            );
         } catch (error) {
            errorName = error.name;
            if (retryCount >= 3) {
               fnErrorData({
                  tokenPaymentId,
                  price,
                  errorName,
               });
               fnError(true);
            } else {
               setRetryCount(retryCount + 1);
               await delay(3000);
               paymentSession = await cxsGetPaymentSessionByTokenPaymentId(
                  tokenPaymentId
               );
            }
         }

         switch (paymentSession.accounts[0].status) {
            case "GCASH_AUTHORISED":
               navigate(`/${mobileNumber}/success`);
               break;

            case "GCASH_REFUSED":
               errorName = "GetPaymentSessionErrorGCash";
               fnErrorData({
                  tokenPaymentId,
                  price,
                  errorName,
               });
               fnError(true);
               break;

            case "GCASH_RECEIVED":
               break;

            case "GCASH_CANCELLED":
               errorName = "GetPaymentSessionErrorGCash";
               fnErrorData({
                  tokenPaymentId,
                  price,
                  errorName,
               });
               fnError(true);
               break;

            case "GCASH_ERROR":
               errorName = "GetPaymentSessionErrorGCash";
               fnErrorData({
                  tokenPaymentId,
                  price,
                  errorName,
               });
               fnError(true);
               break;

            default:
               // Guard clause to check if checkout url has been opened already
               if (paymentSession.checkoutUrl) {
                  try {
                     // Open a new tab
                     window.open(
                        paymentSession.checkoutUrl,
                        "_self",
                        "noreferrer"
                     );
                  } catch (error) {
                     // This is to handle invalid checkout urls
                     console.debug(error);
                  }
               }
               // This delay acts as the refetch interval
               await delay(5000);
               // Recursively call again to check if session is paid
               await retrieveSessionDetails(tokenPaymentId);

               return paymentSession;
         }

         return paymentSession;
      }

      try {
         fnLoading(true);
         let boosterTxnArr = [];
         if (selectedBoosters.length > 0) {
            boosterTxnArr = selectedBoosters.map((booster) => {
               return {
                  serviceId: booster.serviceId,
                  param: booster.param,
                  amount: booster.amount
               };
            });
         }
         
         const gcashTotalPrice = getTotalPrice();

         const paymentSessionTokenPaymentId = await cxsCreatePaymentSession(
            mobileNumber,
            nfParam,
            price,
            gcashTotalPrice,
            extra,
            searchParams.toString(),
            boosterTxnArr
         );
            
         await retrieveSessionDetails(paymentSessionTokenPaymentId);
      } catch (error) {
         errorName = error.name;
         fnErrorData({
            tokenPaymentId: null,
            price: null,
            errorName,
         });
         fnError(true);
      } finally {
         fnLoading(false);
      }

      return;
   };

   return (
      <div className="z-10 w-full max-w-xl rounded-t-2xl border-t bg-white pb-4 shadow-2xl">
         {/* mobile number section */}
         <div className="border-b border-b-neutral-a4 border-opacity-20">
            <div className="flex items-center justify-between px-6 py-4">
               <p className="inline-block whitespace-nowrap rounded bg-neutral-b2 px-2 py-0.5 text-xs font-light text-neutral-a2">
                  Send to
               </p>
               <div className="flex items-center gap-2">
                  <p className="font-semibold text-neutral-a0">{`+${mobileNumber}`}</p>
                  <p className="inline-block whitespace-nowrap rounded bg-neutral-b2 px-2 py-0.5 text-xs font-bold text-neutral-a2">
                     Globe Prepaid
                  </p>
               </div>
               <img
                  src={EditSVG}
                  onClick={() => setIsChangingMobileNumber(true)}
                  alt="Edit account"
               />
            </div>
         </div>
         {/* endof mobile number section */}

         {/* payment option section */}
         <div className="border-b border-b-neutral-a4 border-opacity-20">
            <div className="flex items-center justify-between px-6 py-4">
               <div className="flex gap-1.5">
                  <img src={GCashLogo} alt="GCash" />
                  <p className="font-semibold text-accent-dark">GCash</p>
               </div>

               {/* <img src={ChevronRightSVG} alt="Select payment method" /> */}
            </div>
         </div>
         {/* endof payment option section */}

         {/* subscribe section */}
         <div className="space-y-4 px-6 py-4 font-bold">
            <div className="flex items-center justify-between">
               <p className="text-xs text-neutral-a2">Total Amount</p>
               <p className="text-lg text-neutral-a0">
                  ₱{`${price ? `${getTotalPrice()}.00` : "0.00"}`}
               </p>
            </div>

            <button
               className={`w-full rounded-lg py-2 ${
                  isSubscribeBtnEnabled()
                     ? "bg-primary text-white"
                     : "disabled bg-neutral-a5 text-neutral-a4"
               }`}
               disabled={!isSubscribeBtnEnabled()}
               onClick={() => subscribeToPromo()}
            >
               Subscribe
            </button>
         </div>
         {/* endof subscribe section */}
      </div>
   );
};

/**
 * This utility function returns the container that houses the freebies available for the
 * promo
 * @param {*} param0
 * @returns
 */
const SubCatalogPanelFreebies = ({
   mobileNumber,
   id,
   freebieCount,
   fnNfParam,
   numOfSelectedFreebies,
   setNumbOfSelectedFreebies,
}) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const localEFreebie = getCache("entertainment");
   const localLsFreebie = getCache("lifestyle");

   useEffect(() => {
      if (localEFreebie) setNumbOfSelectedFreebies(1);

      if (localLsFreebie) {
         if (localEFreebie) {
            setNumbOfSelectedFreebies(2);
         } else setNumbOfSelectedFreebies(1);
      }

      /**
       * To be updated once there are promos with only entertainment
       * or only lifestyle freebie
       */
      if (localEFreebie && localLsFreebie) {
         const { selectedNfMainParam } = getNfMainParam({
            id: id,
            entertainment: localEFreebie,
            lifestyle: localLsFreebie,
         });

         fnNfParam(selectedNfMainParam.nf_service_param);
      }
   }, [localEFreebie, localLsFreebie]);

   return (
      <div className="my-2 flex flex-row justify-between rounded-md bg-white px-3 pb-3 drop-shadow-card">
         <div className="flex flex-col">
            <div className="mt-2 flex flex-row">
               <p className="mr-2 text-xs font-semibold text-neutral-a0">{`Choose Freebies (${
                  localEFreebie || localLsFreebie
                     ? `${numOfSelectedFreebies}`
                     : "0"
               }/${freebieCount})`}</p>
               <p className="text-xs text-neutral-a4"> Required</p>
            </div>
            <p className="mr-2 mt-2 text-xs text-neutral-a0">
               Enjoy additional data for apps and get Lifestyle vouchers.
            </p>
            <div className="flex flex-row">
               {localEFreebie && (
                  <span className="my-1 mr-2 whitespace-nowrap rounded-md border border-primary bg-light-blue p-chips text-center text-xs font-bold text-primary">
                     {localEFreebie}
                  </span>
               )}

               {localLsFreebie && (
                  <span className="my-1 mr-2 whitespace-nowrap rounded-md border border-primary bg-light-blue p-chips text-center text-xs font-bold text-primary">
                     {localLsFreebie}
                  </span>
               )}
            </div>
         </div>
         <div className="flex items-center justify-center pt-3">
            <img
               src={ChevronRightSVG}
               onClick={() =>
                  navigate({
                     pathname: `/${mobileNumber}/prepaid/${id}/freebie`,
                     search: searchParams.toString(),
                  })
               }
               className="cursor-pointer"
               alt="Choose Freebies"
            />
         </div>
      </div>
   );
};

/**
 * This utility funciton houses the logic for the extra sub panel component
 * @param {*} param0
 * @returns
 */
const SubCatalogPanelExtra = ({ records, fnExtra, selectedExtra }) => {
   // Sets the default service id if there is only one option
   if (records.length === 1) {
      fnExtra(records[0].nf_service_id);
   }
   let extraIsFullfilled = false;

   if (selectedExtra) {
      const promoExtra = records.filter(
         (record) => record.nf_service_id === selectedExtra
      );
      extraIsFullfilled = promoExtra.length > 0 ? true : false;
   }

   // This utitility funciton handles when a service is selected
   const serviceIsClicked = (serviceId) => {
      fnExtra(serviceId);
   };

   return records.length === 1 ? (
      ""
   ) : (
      <div className="flex flex-col rounded-md bg-white p-3 drop-shadow-card">
         <div className="flex flex-row">
            <p className="mr-2 text-xs font-semibold text-neutral-a0">
               {`Extra (${extraIsFullfilled ? "1" : "0"}/1) `}
            </p>
            <p className="text-xs text-neutral-a4"> Required</p>
         </div>

         <p className="mt-2 text-xs text-neutral-a0">
            Pick your Super Xclusive extra!
         </p>

         <div className="mt-2 grid grid-cols-2 gap-2">
            {records.map((record) => (
               <div
                  key={record.record_id}
                  className={`flex h-12 items-center justify-center rounded-md border-2  hover:border-primary ${
                     selectedExtra === record.nf_service_id
                        ? "border-primary"
                        : "border-highlight"
                  }`}
                  onClick={() => serviceIsClicked(record.nf_service_id)}
               >
                  <p
                     className={`p-2 text-center text-xs font-medium ${
                        selectedExtra === record.nf_service_id
                           ? "text-primary"
                           : "text-accent-dark"
                     }`}
                  >
                     {record.nf_main_freebie_freebie_type}
                  </p>
               </div>
            ))}
         </div>
      </div>
   );
};

const SubCatalogPanelBooster = ({ mobileNumber, id }) => {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const { selectedBoosters } = useBoosters();

   const hasSelectedBoosters = () => {
      return selectedBoosters.length > 0;
   };
   return (
      <div className="my-2 flex flex-row justify-between rounded-md bg-white px-3 pb-3 drop-shadow-card">
         <div className="flex w-full flex-col">
            <div className="mt-2 flex items-center justify-between">
               <div className="flex flex-row">
                  <p className="mr-2 text-xs font-semibold text-neutral-a0">
                     {`Add Booster (${selectedBoosters.length}/3)`}
                  </p>
                  <p className="text-xs text-neutral-a4">Optional</p>
               </div>
               <div className="flex items-center justify-center ">
                  <img
                     src={ChevronRightSVG}
                     className="h-4 w-4 cursor-pointer"
                     alt="Choose Booster"
                     onClick={() =>
                        navigate({
                           pathname: `/${mobileNumber}/prepaid/${id}/boosters`,
                           search: searchParams.toString(),
                        })
                     }
                  />
               </div>
            </div>
            <div className="mt-2 flex w-full flex-col">
               <p className="text-xs">
                  Get more out of your promo with unli calls to all networks or
                  additional data for streaming, listening, and shopping.
               </p>
               <div className="mt-2 flex flex-row">
                  {hasSelectedBoosters() &&
                     selectedBoosters.map((booster) => (
                        <span
                           key={booster.serviceId}
                           className="my-1 mr-2 whitespace-nowrap rounded-md border border-primary bg-light-blue p-chips text-center text-xs font-bold text-primary"
                        >
                           {booster.name}
                        </span>
                     ))}
               </div>
            </div>
         </div>
      </div>
   );
};

const GetSubPanels = ({
   records,
   fnExtra,
   selectedExtra,
   mobileNumber,
   id,
   fnNfParam,
   renewParams,
   availGoRenew,
   fnGoRenew,
   onSelectDefaultOnly,
   fnLearnMore,
   fnTnC,
   numOfSelectedFreebies,
   setNumbOfSelectedFreebies,
   isBoosterAllowed,
}) => {
   const { numberOfRequiredFreebies } = sortFreebiesById(id);
   const onlyHasDefaults = checkForDefaultExtras(id);

   useEffect(() => {
      // Set the extra paramater here to the service id that incurs a charge
      if (onlyHasDefaults && !availGoRenew) {
         const { selectedNfMain } = getNfMainServiceId(id);
         const { selectedNfMainParam } = getNfMainParam({ id });

         onSelectDefaultOnly(
            selectedNfMain.nf_service_id,
            selectedNfMainParam.nf_service_param
         );
      }
   }, [onlyHasDefaults]);

   /**
    * Utility function that handles if GoRenew is clicked
    */
   const handleGoRenew = () => {
      // If GoRenew is not here, then make it the selected Param and Id
      fnExtra(renewParams.recurrId);
      fnNfParam(renewParams.recurrParam);

      fnGoRenew(!availGoRenew);
   };

   return (
      <>
         {/* Extras panel */}
         {records.length > 1 && !onlyHasDefaults && (
            <SubCatalogPanelExtra
               records={records}
               fnExtra={fnExtra}
               selectedExtra={selectedExtra}
            />
         )}
         {/* Freebie panel */}
         {numberOfRequiredFreebies > 0 && (
            <SubCatalogPanelFreebies
               freebieCount={numberOfRequiredFreebies}
               mobileNumber={mobileNumber}
               id={id}
               fnNfParam={fnNfParam}
               numOfSelectedFreebies={numOfSelectedFreebies}
               setNumbOfSelectedFreebies={setNumbOfSelectedFreebies}
            />
         )}
         {/* GoRenew panel */}
         {renewParams && (
            <div className="my-2 flex flex-row justify-between rounded-md bg-white px-3 pb-3 drop-shadow-card">
               <div className="flex w-full flex-col">
                  <div className="mt-2 flex items-center justify-between">
                     <p className="mr-2 text-xs font-semibold text-neutral-a0">{`GoRENEW`}</p>
                     <div>
                        <label
                           htmlFor="goRenew"
                           className="flex cursor-pointer items-center"
                        >
                           <div className="relative">
                              <input
                                 type="checkbox"
                                 id="goRenew"
                                 className="sr-only"
                                 onChange={() => handleGoRenew()}
                              />
                              <div
                                 className={`block ${
                                    availGoRenew ? "bg-primary" : "bg-gray-600"
                                 } h-4 w-8 rounded-full`}
                              ></div>
                              <div className="dot absolute left-0.5 top-px h-3.5 w-3.5 rounded-full bg-white transition"></div>
                           </div>
                        </label>
                     </div>
                  </div>
                  <div className="mt-2 flex w-full flex-col">
                     <p className="text-xs">
                        Automatically renew your promo registration after its
                        expiry every 7 days. The cost of promo will be deducted
                        from recipient's load. Only one GoRenew subscription can
                        be purchased at a time.
                        <button
                           className="text-primary"
                           onClick={() => fnLearnMore(true)}
                        >
                           Learn more.
                        </button>
                     </p>
                     <div className="flex flex-row">
                        <button
                           className="text-xs text-primary"
                           onClick={() => fnTnC(true)}
                        >
                           Terms and Condition
                        </button>
                        <p className="text-xs">&nbsp;apply</p>
                     </div>
                  </div>
               </div>
            </div>
         )}

         {/* Boosters Panel */}
         {isBoosterAllowed && (
            <SubCatalogPanelBooster mobileNumber={mobileNumber} id={id} />
         )}
      </>
   );
};

const CatalogPanel = ({
   id,
   name,
   price,
   validity,
   description,
   onSelect,
   selectedPromo,
   records,
   mobileNumber,
   selectedExtra,
   fnExtra,
   fnNfParam,
   renewParams,
   availGoRenew,
   fnGoRenew,
   onSelectDefaultOnly,
   fnLearnMore,
   fnTnC,
   fnError,
   setNumbOfSelectedFreebies,
   numOfSelectedFreebies,
   isBoosterAllowed,
}) => {
   const isCatalogSelected = selectedPromo === id;
   const { setSelectedBoosters } = useBoosters();
   
   

   const handlePromoClicked = () => {
      onSelect(id, price, name, validity);
      if (!isCatalogSelected && isBoosterAllowed) {
         setSelectedBoosters([]);
      }
      
      if (!renewParams) {
         fnGoRenew(false);
      }
   };

   return (
      <div
         className={`space-y-4 rounded-lg px-4 pb-5 pt-3 shadow-lg hover:border hover:border-primary hover:bg-light-blue ${
            isCatalogSelected
               ? "border border-primary bg-light-blue"
               : "bg-white"
         }`}
         onClick={() => handlePromoClicked()}
         data-testid={`catalog-panel-item-${id}`}
      >
         <div className="flex items-center justify-between font-bold">
            <p
               className={`${
                  isCatalogSelected ? "text-primary " : "text-neutral-a0"
               }`}
            >
               {name}
            </p>

            <p className="text-primary">₱{price}.00</p>
         </div>

         <p className="text-xs">{description}</p>

         {isCatalogSelected && (
            <GetSubPanels
               records={records}
               fnExtra={fnExtra}
               selectedExtra={selectedExtra}
               mobileNumber={mobileNumber}
               id={id}
               fnNfParam={fnNfParam}
               renewParams={renewParams}
               availGoRenew={availGoRenew}
               fnGoRenew={fnGoRenew}
               onSelectDefaultOnly={onSelectDefaultOnly}
               fnLearnMore={fnLearnMore}
               fnTnC={fnTnC}
               setNumbOfSelectedFreebies={setNumbOfSelectedFreebies}
               numOfSelectedFreebies={numOfSelectedFreebies}
               isBoosterAllowed={isBoosterAllowed}
            />
         )}
      </div>
   );
};

const ChangeMobileNumberModal = ({ currentMobileNumber, onModalClose }) => {
   const {
      isFetching,
      mobileNumber: editMobileNumber,
      setMobileNumber: editSetMobileNumber,
      isPrepaid: editIsGlobePrepaid,
      isCxsError,
      isExpired,
   } = useBrandedMobileNumber(currentMobileNumber);
   const [formError, setFormError] = useState();
   const [checkBrandError, setCheckBrandError] = useState(false);

   useEffect(() => {
      setCheckBrandError(isCxsError || isExpired);
   }, [isCxsError, isExpired]);

   return (
      <ModalFrame>
         {isFetching && <WholeScreenLoading></WholeScreenLoading>}

         <div className="fixed bottom-0 w-full max-w-xl space-y-4 rounded-t-2xl border-t bg-white px-6 py-5 shadow-2xl">
            <div className="space-y-2">
               <label
                  htmlFor="mobile-number"
                  className="text-sm font-bold text-neutral-a0"
               >
                  Update Mobile Number
               </label>

               <MobileNumberField
                  formError={formError}
                  mobileNumber={editMobileNumber}
                  isPrepaid={editIsGlobePrepaid}
                  isCheckingBrand={isFetching}
                  isCxsError={checkBrandError}
                  isExpired={checkBrandError}
                  onChange={(e) => {
                     const formatResult = checkAndFormatNumber(e.target.value);
                     if (formatResult !== "") {
                        e.target.value = formatResult;
                        editSetMobileNumber(formatResult);
                        setFormError(false);
                     } else {
                        if (
                           !/^\d+$/.test(e.target.value.trim()) &&
                           e.target.value.trim().length !== 0
                        ) {
                           setFormError(true);
                        } else if (e.target.value.trim().length > 11) {
                           setFormError(true);
                        } else {
                           setFormError(false);
                           setCheckBrandError(false);
                        }
                     }
                  }}
               ></MobileNumberField>
               {!editIsGlobePrepaid &&
                  !formError &&
                  !isCxsError &&
                  !isExpired && (
                     <p className="text-sm text-neutral-a3">
                        Only Globe Prepaid number can buy promos
                     </p>
                  )}
            </div>

            <button
               disabled={!editIsGlobePrepaid}
               className={`w-full rounded-lg py-2 font-bold ${
                  editIsGlobePrepaid
                     ? "bg-primary text-white"
                     : "disabled bg-neutral-a5 text-neutral-a4"
               }`}
               onClick={() => onModalClose(true, editMobileNumber)}
            >
               Update
            </button>

            <button
               className="w-full py-2 text-center font-bold text-primary"
               onClick={() => onModalClose(true, currentMobileNumber)}
            >
               Cancel
            </button>
         </div>
      </ModalFrame>
   );
};

const PromoCatalog = () => {
   const navigate = useNavigate();
   const [searchParams, setSearchParams] = useSearchParams();
   const { isError, setIsError, setErrorData } = useError();

   const { isFetching, mobileNumber, setMobileNumber } = useBrandedMobileNumber(
      useParams().mobileNumber
   );

   const [numOfSelectedFreebies, setNumbOfSelectedFreebies] = useState(0);
   const [isChangingMobileNumber, setIsChangingMobileNumber] = useState(false);

   const [selectedCatalog, setSelectedCatalog] = useState(
      getCache("ng1_bcf_selected_categorylog") ?? ""
   );

   const [selectedExtra, setSelectedExtra] = useState(
      getCache("ng1_bcf_nf_service_id") ?? ""
   );

   const [selectedNfParam, setSelectedNfParam] = useState("");
   const [availGoRenew, setAvailGoRenew] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isLearnMoreOpened, setIsLearnMoreOpened] = useState(false);
   const [isTnCOpened, setIsTnCOpened] = useState(false);

   const transactionFailureType = getCache("ng1_bcp_transaction_failed_type");
   const transactionRefundAmount = getCache(
      "ng1_bcp_transaction_failed_refund_amount"
   );

   const getTotalPrice = () => {
      const totalPrice = parseInt(selectedCatalog.price);
      return totalPrice;
   };

   // Use effect to update the selected catalog
   useEffect(() => {
      setCache("ng1_bcps_selected_categorylog", selectedCatalog);
      setCache("ng1_bcf_selected_categorylog", selectedCatalog);
   }, [selectedCatalog]);

   // Use effect to update the service id
   useEffect(() => {
      setCache("ng1_bcf_nf_service_id", selectedExtra);
   }, [selectedExtra]);

   // Use effect to update the service param
   useEffect(() => {
      setCache("ng1_bcf_nf_service_param", selectedNfParam);
   }, [selectedNfParam]);

   useEffect(() => {
      // new mobileNumber should also reflect on the URI
      navigate({
         pathname: `/${mobileNumber}/prepaid`,
         search: searchParams.toString(),
      }, {replace : true});
   }, [mobileNumber]);

   // Use effect to check only if the value of the cached failed transaction
   useEffect(() => {
      // Guard clause to check if there is a cached failed transaction
      if (transactionFailureType) {
         if (transactionFailureType === "refund") {
            let price = transactionRefundAmount;
            setErrorData({
               price,
            });
         } else setErrorData({});

         setIsError(true);
      }
   }, [transactionFailureType, transactionRefundAmount]);

   // Use effect to check if the url search params has transactionFailed=true
   useEffect(() => {
      // Guard clause to check if the url is the redirect url from failed gcash
      if (searchParams.get("transactionFailed")) {
         setIsError(true);
         searchParams.delete("transactionFailed");
         setSearchParams(searchParams);
      }
   }, [searchParams.get("transactionFailed")]);

   const mapRenewParams = (recurr, recurr_param) => {
      if (recurr.length === 0) {
         return null;
      } else {
         const noChargeRecurrId = recurr.filter(
            (record) => record.charging === "0"
         );
         const noChargeRecurrParam = recurr_param.filter(
            (record) => record.charging === "0"
         );
         return {
            recurrId: noChargeRecurrId[0].nf_recurr_id,
            recurrParam: noChargeRecurrParam[0].nf_recurr_param,
         };
      }
   };

   return (
      <div className="pancake-stack-v1 h-screen max-h-screen min-h-screen max-w-xl overscroll-none">
         <TopSection></TopSection>

         <div className="space-y-5 overflow-y-scroll px-4 pb-8 pt-4">
            {PREPAID_PROMOS_DATA.map((data, _) => (
               <CatalogPanel
                  key={data.entity_id}
                  id={data.entity_id}
                  name={data.name}
                  price={data.price}
                  validity={data.validity}
                  description={data.short_description}
                  onSelect={(promoId, promoPrice, promoName, promoValidity) => {
                     removeCache("selectedCatalog");
                     removeCache("selectedExtra");
                     removeCache("selectedNfParam");

                     setSelectedCatalog({
                        id: promoId,
                        price: promoPrice,
                        name: promoName,
                        validity: promoValidity,
                     });
                  }}
                  selectedPromo={selectedCatalog.id}
                  records={data.nf_main}
                  mobileNumber={mobileNumber}
                  selectedExtra={selectedExtra}
                  fnExtra={setSelectedExtra}
                  fnNfParam={setSelectedNfParam}
                  renewParams={mapRenewParams(
                     data.nf_recurr,
                     data.nf_recurr_param
                  )}
                  fnGoRenew={setAvailGoRenew}
                  availGoRenew={availGoRenew}
                  onSelectDefaultOnly={(nfMain, nfMainParam) => {
                     setSelectedExtra(nfMain);
                     setSelectedNfParam(nfMainParam);
                  }}
                  fnLearnMore={setIsLearnMoreOpened}
                  fnTnC={setIsTnCOpened}
                  setNumbOfSelectedFreebies={setNumbOfSelectedFreebies}
                  numOfSelectedFreebies={numOfSelectedFreebies}
                  isBoosterAllowed={data.is_booster_allowed === "Yes"}
               ></CatalogPanel>
            ))}
         </div>

         {/* 
            This renders the bottom panel where the user can create promo
            and change number
         */}
         <BottomPanel
            extra={selectedExtra}
            nfParam={selectedNfParam}
            price={getTotalPrice()}
            mobileNumber={mobileNumber}
            setIsChangingMobileNumber={setIsChangingMobileNumber}
            fnLoading={setIsLoading}
            fnError={setIsError}
            fnErrorData={setErrorData}
            selectedCatalog={selectedCatalog}
         ></BottomPanel>

         {/* This renders the pop-up modal when there is an error  */}
         {isError && <ErrorModal></ErrorModal>}

         {/* 
            This renders the loading screen
          */}
         {(isFetching || isLoading) && (
            <WholeScreenLoading></WholeScreenLoading>
         )}

         {/* This opens the pop-up modal for Learn More of GoRenew */}
         {isLearnMoreOpened && (
            <GoRENEWmodal setIsOpen={setIsLearnMoreOpened}></GoRENEWmodal>
         )}

         {/* This renders the pop-up modal for the terms and conditions */}
         {isTnCOpened && (
            <TermsAndConditions setIsOpen={setIsTnCOpened}></TermsAndConditions>
         )}

         {/* 
            This renders the pop-up modal that shows up when 
            user wants to change the mobile number
         */}
         {isChangingMobileNumber && (
            <ChangeMobileNumberModal
               currentMobileNumber={mobileNumber}
               onModalClose={(shouldCloseModal, newMobileNumber) => {
                  if (mobileNumber !== newMobileNumber) {
                     setMobileNumber(newMobileNumber);
                  }

                  setIsChangingMobileNumber(!shouldCloseModal);
               }}
            ></ChangeMobileNumberModal>
         )}
      </div>
   );
};

export default PromoCatalog;
