import React from "react";
import { useError } from "../providers/Error";
import MaintenanceSVG from "../assets/maintenance-logo.svg";
import { removeCache } from "../services/cache";

const errorNotices = {
   CreatePaymentSessionError: {
      notice: "Something went wrong with the promo subscription. ",
   },
   GetPaymentSessionError: {
      notice: "Something went wrong with the promo subscription. ",
   },
   GetPaymentSessionErrorPaid: {
      notice:
         "Something went wrong with the promo subscription. Don't worry \
      we've refunded your payment to your GCash",
   },
   GetPaymentSessionErrorGCash: {
      notice: "Something went wrong with the promo subscription. ",
   },
   DEFAULT: {
      notice:
         "We encountered an issue with the promo subscription. Please try again.",
   },
};

const ErrorModal = () => {
   const { setIsError, errorData } = useError();
   const { tokenPaymentId, price, errorName } = errorData;
   // TODO: To change to conditional error notices depending on error name
   const notice = errorNotices["DEFAULT"].notice;

   return (
      <div
         role="status"
         className="absolute inset-0 z-50 flex h-full min-h-max min-w-xs items-center justify-center bg-gray-500 bg-opacity-60 px-5"
      >
         <div className="min-w-xs max-w-md space-y-4 rounded-md border-t bg-white px-6 py-5  shadow-2xl">
            <div className="space-y-6 py-4 text-center">
               <p className="px-4 text-lg font-bold">
                  Sorry we weren't able to register your promo.
               </p>
               <p className="text-md text-neutral-a1">{notice}</p>

               {price && (
                  <div className="border-t border-t-neutral-a4 border-opacity-20">
                     <div className="flex items-start justify-between pt-4">
                        <div className="flex gap-1.5">
                           <p className="text-md font-semibold text-accent-dark">
                              Refunded
                           </p>
                        </div>
                        <p className="text-md text-neutral-a0">
                           ₱{`${price ? `${price}.00` : "0.00"}`}
                        </p>
                     </div>
                  </div>
               )}

               <button
                  className={`w-full rounded-lg bg-primary py-2 text-white`}
                  onClick={() => {
                     setIsError(false);
                     removeCache("ng1_bcp_transaction_failed_type");
                     removeCache("ng1_bcp_transaction_failed_refund_amount");
                  }}
               >
                  Okay
               </button>
            </div>
         </div>
      </div>
   );
};

export default ErrorModal;
