import React, { useEffect, useState } from "react";
import SuccessLogo from "../assets/success-icon.svg";
import DownloadIcon from "../assets/download-icon.svg";
import CopyIcon from "../assets/copy-icon.svg";
import CloseIconSVG from "../assets/shapeclose.png";
import Receipt_UpperDeco from "../assets/receipt-decoration.png";
import Receipt_LowerDeco from "../assets/receipt-decoration-lower.png";
import RewardsIconSVG from "../assets/rewards-icon.svg";
import { cxsGetPaymentSessionByTokenPaymentId } from "../services/cxs";
import { getCache, setCache } from "../services/cache";

import { useNavigate, useSearchParams } from "react-router-dom";
import html2canvas from "html2canvas";

// Use the mock function in your tests

function Success() {
   const [isLoading, setIsLoading] = useState(false);
   const navigate = useNavigate();
   const [tokenPaymentId, setTokenPaymentId] = useState("");
   const [searchParams] = useSearchParams();
   const mobileNumber = searchParams.get("mobileNumber");
   const [selectedCatalog, setSelectedCatalog] = useState(
      getCache("ng1_bcps_selected_categorylog") ?? ""
   );

   const cachedGcashTotalPrice =
      getCache("ng1_bcp_gcash_total_price") ?? selectedCatalog.price;

   const date = Date.now();
   const startDate = new Date(date);
   const datePart = startDate.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
   });

   const timePart = startDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
   });

   const endDate = new Date(date);
   const endOfValidity = new Date(
      endDate.setDate(endDate.getDate() + parseInt(selectedCatalog.validity))
   );

   const formatted_endOfValidity = endOfValidity.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
   });

   const formatted_mobileNumber = "0" + mobileNumber.toString().slice(2);
   useEffect(() => {
      const retrieveSessionDetails = async () => {
         const cachedTokenPaymentId = getCache("ng1_bcf_token_payload_id");

         setTokenPaymentId(cachedTokenPaymentId);
         const paymentSession = await cxsGetPaymentSessionByTokenPaymentId(
            cachedTokenPaymentId
         );

         // Guard clause to check if the response has a refund object
         if (
            paymentSession.accounts[0].transactions[0].provisionStatus ===
            "FAILED"
         ) {
            // Delete the mobileNumber here from the search params to be reused
            searchParams.delete("mobileNumber", mobileNumber);

            if (paymentSession.accounts[0].refund) {
               // Cache the refund amount and the failure type to be refund
               setCache("ng1_bcp_transaction_failed_type", "refund");
               setCache(
                  "ng1_bcp_transaction_failed_refund_amount",
                  paymentSession.accounts[0].refund.amount
               );
            } else {
               // Cache the failure type to be a generic transaction
               setCache("ng1_bcp_transaction_failed_type", "transaction");
            }

            // Navigate back to promo catalog: Should prompt an error modal
            navigate({
               pathname: `/${mobileNumber}/prepaid`,
               search: searchParams.toString(),
            });
         }

         setIsLoading(false);
      };

      retrieveSessionDetails();
   }, [mobileNumber]);

   const copyToClipboard = () => {
      // Create a temporary textarea element
      const tempTextarea = document.createElement("textarea");
      tempTextarea.value = tokenPaymentId;

      // Append the textarea to the document
      document.body.appendChild(tempTextarea);

      // Select the text in the textarea
      tempTextarea.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary textarea from the document
      document.body.removeChild(tempTextarea);

      window.location.hash = "copyTokenPaymentId";
   };

   const onDownload = () => {
      //receiptCapture is created dynamically when the user clicks the download button, htmltocanvas is unable to find the element and throws an error, to fix the issue I added settimeout
      setTimeout(() => {
         html2canvas(document.getElementById("receiptCapture")).then(
            (canvas) => {
               document.body.appendChild(canvas);
               const canvasData = canvas.toDataURL("image/png");

               const downloadLink = document.createElement("a"); // this variable will be used to download the image of the receipt.

               // Add URL hash after triggering download
               window.location.hash = "SuccessReceipt";

               downloadLink.href = canvasData;
               downloadLink.download = `IMG_${tokenPaymentId.substring(3)}.png`;
               document.body.appendChild(downloadLink);
               downloadLink.click();

               document.body.removeChild(canvas);
            }
         );
      }, 500);

      //returns the default attributes of the meta tag
      if (window.innerWidth < 1024) {
         document
            .getElementById("viewport")
            .setAttribute("content", "width=device-width, initial-scale=1");
      }
   };
   return (
      <div id="receiptCapture">
         <meta
            id="viewport"
            content="width=device-width, initial-scale=1.0"
            name="viewport"
         />
         <div
            className={`pancake-stack-v1 h-screen max-h-screen min-h-screen max-w-xl bg-receipt-bg ${
               isLoading ? "opacity-5" : ""
            } drop-shadow-sm`}
         >
            <div className="flex flex-row justify-between p-6 mx-6">
               <div className="flex flex-col">
                  <p className="text-lg font-semibold text-white">
                     Here's the receipt!
                  </p>
                  <p className="text-xs font-semibold text-receipt-time">
                     {`${datePart}, ${timePart}`}
                  </p>
               </div>
               <div className="flex items-center justify-center pt-3">
                  <img
                     data-html2canvas-ignore="true"
                     src={CloseIconSVG}
                     onClick={() =>
                        navigate({
                           pathname: "/",
                           search: searchParams.toString(),
                        })
                     }
                     className="cursor-pointer"
                     alt="Go Back"
                  />
               </div>
            </div>
            <div className="flex flex-col w-11/12 mx-6 pancake-stack-v1 font-roboto">
               <div className="mx-6">
                  <img
                     className="w-full "
                     src={Receipt_UpperDeco}
                     alt="Receipt Upper Decoration Frills"
                  />
               </div>
               <div className="flex flex-col pt-10 mx-6 bg-white">
                  {/* The success logo and the download logo */}
                  <div className="relative">
                     <div className="flex items-center justify-center w-full text-center">
                        <span>
                           <img src={SuccessLogo} alt="Success icon" />
                        </span>
                     </div>
                     <div className="absolute top-0 flex-none right-6 w-7">
                        <img
                           data-html2canvas-ignore="true"
                           className="w-full cursor-pointer"
                           src={DownloadIcon}
                           alt="Donwload receipt here"
                           onClick={() => {
                              onDownload();
                           }}
                        />
                     </div>
                  </div>
                  <div className="flex flex-col items-center justify-center mt-3">
                     <p className="block text-lg font-semibold text-receipt-status">
                        Success!
                     </p>
                     <p className="block text-sm semi text-neutral-a1">
                        Your payment is complete!
                     </p>
                     <p className="block mt-1 mb-4 text-sm text-neutral-a1">
                        We texted you the confirmation.
                     </p>
                     <div className="flex justify-center w-10/12 p-2 m-2 text-center border rounded border-primary bg-accent-light">
                        <div
                           className="flex-auto"
                           style={{
                              textAlign: "-webkit-right",
                           }}
                        >
                           <img src={RewardsIconSVG} alt="Rewards Icon" />
                        </div>
                        <div className="flex-auto pt-1 text-center">
                           <p className="text-xs align-middle text-neutral-a1">
                              You’ve also earned points from this transaction.
                           </p>
                        </div>
                     </div>
                     <div className="flex items-center justify-between w-10/12 py-3 border-b border-neutral-a5">
                        <p className="text-xs font-semibold text-neutral-a3">
                           Sent to
                        </p>
                        <p className="text-xs font-medium text-right text-neutral-a0">
                           {formatted_mobileNumber}
                        </p>
                     </div>
                     <div className="flex items-center justify-between w-10/12 pt-3 border-neutral-a4">
                        <p className="text-xs font-semibold text-neutral-a3">
                           Promo
                        </p>
                        <p className="text-xs font-medium text-right text-neutral-a0">
                           {selectedCatalog.name}
                        </p>
                     </div>
                     <div className="flex items-center justify-between w-10/12 pt-1 pb-3 border-b border-neutral-a5">
                        <p className="text-xs font-semibold text-neutral-a3">
                           Validity
                        </p>
                        <p className="text-xs font-medium text-right text-neutral-a0">
                           {`${selectedCatalog.validity} day${
                              selectedCatalog.validity === 1 ? "" : "s"
                           } (${formatted_endOfValidity})`}
                        </p>
                     </div>
                     <div className="flex items-center justify-between w-10/12 py-3 border-b border-neutral-a5">
                        <p className="text-xs font-semibold text-neutral-a3">
                           Amount
                        </p>
                        <p className="text-xs font-semibold text-right text-neutral-a0">
                           {`₱${cachedGcashTotalPrice}.00`}
                        </p>
                     </div>
                     <div className="flex items-center justify-between w-10/12 py-3 border-b border-neutral-a5">
                        <div className="flex flex-col">
                           <p className="text-xs font-semibold text-neutral-a3">
                              Total Amount
                           </p>
                           <p className="text-xs font-normal text-neutral-a3">
                              Paid using GCash
                           </p>
                        </div>
                        <p className="text-xl font-semibold text-right text-neutral-a0">
                           {`₱${cachedGcashTotalPrice}.00`}
                        </p>
                     </div>
                     <div className="flex items-center justify-between w-10/12 py-3 border-b border-neutral-a5">
                        <div className="flex flex-col w-full">
                           <p className="text-xs font-semibold text-neutral-a3">
                              Reference No.
                           </p>
                           <div className="grid grid-cols-2">
                              <div className="flex items-center justify-between p-1 ">
                                 <p
                                    className="text-xs font-semibold text-neutral-a0"
                                    id="tokenPaymentId"
                                 >
                                    {tokenPaymentId}
                                 </p>
                              </div>
                              <div className="flex items-center justify-end">
                                 <span className="flex items-center justify-center border rounded-full -pr-2 -pl-4 h-7 w-7">
                                    <img
                                       className="cursor-pointer"
                                       src={CopyIcon}
                                       alt="Copy Reference number"
                                       onClick={() => copyToClipboard()}
                                       id="copytoken"
                                    />
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="h-12 mx-6 bg-white"></div>
               <div className="-py-20 mx-6 mt-[-10] flex justify-center">
                  <img
                     className="w-full"
                     src={Receipt_LowerDeco}
                     alt="Receipt Lower Decoration Frills"
                  />
               </div>
            </div>

            <div className="px-6 py-4 space-y-4">
               <button
                  data-html2canvas-ignore="true"
                  className={`mx-6 mb-12 w-11/12 rounded-lg bg-primary py-2 font-bold text-white`}
                  onClick={() =>
                     navigate({
                        pathname: "/",
                        search: searchParams.toString(),
                     })
                  }
               >
                  Done
               </button>
            </div>
         </div>
         {isLoading ? (
            <div
               role="status"
               className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-2/4"
            >
               <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                     fill="currentColor"
                  />
                  <path
                     d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                     fill="currentFill"
                  />
               </svg>
            </div>
         ) : (
            <></>
         )}
      </div>
   );
}

export default Success;
