import React from "react";
import CloseIconSVG from "../assets/close-icon.svg";

const GoRENEWmodal = ({ setIsOpen }) => {
   return (
      <div className="absolute inset-0 z-30 flex h-full min-w-xs items-center justify-center bg-gray-700 bg-opacity-60">
         <div className="z-40 flex h-full w-full max-w-xl flex-col space-y-4 overflow-hidden overflow-y-scroll rounded-b-2xl rounded-t-2xl border-t bg-white px-6 py-5 shadow-2xl hover:overflow-y-scroll [&::-webkit-scrollbar]:hidden ">
            <img
               src={CloseIconSVG}
               alt="close_btn"
               className=" ml-[90%] h-8 cursor-pointer "
               onClick={() => setIsOpen(false)}
            />
            <span className="border-b border-b-neutral-a4 border-opacity-50"></span>
            <h5 className="text-xs font-bold text-neutral-a3 opacity-80">
               HELP
            </h5>
            <h2 className="font-semibold text-accent-dark">What is GoRENEW</h2>
            <p className="text-sm">
               GoRENEW is a feature that gives Globe Prepaid customers the
               convenience to automatically register to their favorite promos.
               Registration renewal is open to select 7-day, 15-day and 30-day
               Globe Prepaid promos: Go90, GoUNLI95, SURF4ALL99, SURF4ALL249,
               GoEXTRA90, GoEXTRA99, Go90 for Students, Go250, Go400, GoUnli180,
               and GoUNLI350
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               How do I register?
            </h2>
            <p className="text-sm">
               You can register to GoRENEW promos via different channels:
            </p>
            <dl>
               <dt className="font-semibold text-accent-dark">1. GlobeOne</dt>
               <dd className="ml-5 text-sm text-neutral-a2">
                  Activate the GoRENEW button under propmo description before
                  proceeding to payment
               </dd>
               <dt className="font-semibold text-accent-dark">2. GCash</dt>
               <dd className="ml-5 text-sm text-neutral-a2">
                  Search for GoRENEW tab in Buy Load under Globe Prepaid
               </dd>
               <dt className="font-semibold text-accent-dark">3. SMS</dt>
               <dd className="ml-5 text-sm text-neutral-a2">
                  Just text &lt;Promo Keyword&gt; &lt;space&gt;RENEW and send to
                  8080
               </dd>
               <dt className="font-semibold text-accent-dark">4. *143#</dt>
               <dd className="ml-5 text-sm text-neutral-a2">
                  Select "Subscribe and Renew" after the promo details
               </dd>
            </dl>
            <br />
            <h2 className="font-semibold text-accent-dark">
               What promo will be renewed?
            </h2>
            <p className="text-sm">
               The promo that will get automatically renewed is the same as the
               first promo you registered to in GoRENEW. For example, if you
               subscribed to renewable Go90, the succeeding registrations will
               also be Go90. If you wish to register to another promo for
               renewal, you need to stop the GoRENEW subscription of your
               current promo, and register to the new promo that you wanted.
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               How will I be charged?
            </h2>
            <p className="text-sm">
               Cost of Globe Prepaid promos that will be renewed will be charged
               to the customer's load wallet. Because of this, GoRENEW availers
               need to make sure that they have enough load in their wallet
               before the schedule of promo renewal. If you do not have enough
               load balance, your promo renewal will not push through, and your
               GoRENEW subscription will be cancelled.
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               Can I register to another GoRENEW subscription even if have an
               active GoRENEW subscription?
            </h2>
            <p className="text-sm">
               No. You can only have 1 active GoRENEW subscription at a given
               time. If you wish to change the renewable promo that you are
               subscribed to, you need to stop your current active GoRENEW
               subscription and then register to the new one.
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               Can I register to other Globe Prepaid promos even if I have an
               active GoRENEW subscription?
            </h2>
            <p className="text-sm">Yes you can!</p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               How can I stop my GoRENEW subscription?
            </h2>
            <p className="text-sm">
               There are 2 ways to stop your GoRENEW subscription:
            </p>
            <ol className="text-sm">
               <li>
                  1. Not having enough load balance during scheduled promo
                  renewal
               </li>
               <li>2. Texting GORENEW STOP to 8080 for free</li>
            </ol>
            <br />
         </div>
      </div>
   );
};

const TermsAndConditions = ({ setIsOpen }) => {
   return (
      <div className="absolute inset-0 z-30 flex h-full min-w-xs items-center justify-center bg-gray-700 bg-opacity-60">
         <div className="z-40 flex h-full w-full max-w-xl flex-col space-y-4 overflow-hidden overflow-y-scroll rounded-b-2xl rounded-t-2xl border-t bg-white px-6 py-5 shadow-2xl hover:overflow-y-scroll [&::-webkit-scrollbar]:hidden ">
            <img
               src={CloseIconSVG}
               alt="close_btn"
               className=" ml-[90%] h-8 cursor-pointer "
               onClick={() => setIsOpen(false)}
            />
            <span className="border-b border-b-neutral-a4 border-opacity-50"></span>
            <h2 className="font-semibold text-accent-dark">
               Terms and Conditions
            </h2>
            <p className="text-sm">
               These Special Terms supplement the provisions of the Subscription
               Agreement ("SA") and Terms and Conditions specific to the Globe
               Prepaid GoRENEW Promos ("T&C") (the SA and T&C shall be
               collectively referred to as "Agreement"). In the event of any
               conflict or inconsistency between the provisions of the Agreement
               and these Special Terms, the provisions of these Special Terms
               shall prevail as to the subject matter herein contained. All
               other terms and conditions of the Agreement not otherwise
               inconsistent with these Special Terms shall remain binding and
               effective
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">
               Exclusive Offer to Globe Prepaid Customers
            </h2>
            <p className="text-sm">
               Offer to Globe Prepaid Customers Offer is eligible for Globe
               Prepaid customers. Globe reserves the right to terminate if found
               violating these Special Terms or the Agreement. Globe Prepaid's
               GoRENEW promos include: Go90, GO120, Go140, Go250, Go400,
               GoUNLI95, GoUNLI180, SURFALL99, and SURFALL249. App-exclusive
               offers (Cash and New GlobeOne exclusive promo): GoEXTRA90,
               GoEXTRA99, and Go90 for Students. Mechanics GoRENEW is a feature
               that gives Globe Prepaid
            </p>
            <br />
            <h2 className="font-semibold text-accent-dark">Mechanics</h2>
            <p className="text-sm">
               GoRENEW is a feature that gives Globe Prepaid customers the
               convenience to automatically register to their favorite promos.
               Registration renewal is open to selected 7-day, and 15-day Globe
               Prepaid promos. Customers can register via different channels
               such as texting the keyword, registering via the New GlobeOne
               app, GCash app, or via* 143#*. Promo registration will
               automatically be renewed after its expiry and the cost of the
               promo will be charged to customer's load balance. Because of
               this, GoRENEW availers need to make sure that they have enough
               load in their wallet before the schedule of promo renewal. If
               customer does not have enough load balance, your promo renewal
               will not push through, and your GoRENEW subscription will be
               cancelled. To stop the GoRENEW subscription, customers can (1)
               text GORENEW STOP to 8080 or (2) click on the click on the
               "Unsubscribe" button on the homepage of your New GlobeOne app
               under your GoRENEW promo and (3) not having enough load balance
               during scheduled promo renewal. By subscribing "I hereby agree
               and accept. ", subscriber hereby confirms that he/she has read
               and understood the foregoing Special Terms specific to the Globe
               Prepaid's auto renewable promos and the Data Privacy Policy
               (https://www globe.com.ph/privacy-policy.html) and that he/ she
               agrees to be bound thereby.
            </p>
            <br />
         </div>
      </div>
   );
};

export { GoRENEWmodal, TermsAndConditions };
