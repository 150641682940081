import React, { useState } from "react";
import ContactSVG from "../assets/contact-icon.svg";
import { checkAndFormatNumber } from "../utils/formatNumber";
import BrandTag from "./BrandTag";
import ErrorTag from "./ErrorTag";

const MobileNumberField = ({
   mobileNumber,
   isCheckingBrand,
   isPrepaid,
   onChange,
   formError,
   isCxsError,
   isExpired,
}) => {
   const [showBrand, setShowBrand] = useState(isPrepaid);

   const checkIfPrepaid = ({ mobileNumber, showBrand, isPrepaid }) => {
      if (isCxsError || isExpired) return;

      if (mobileNumber && showBrand) {
         return isPrepaid ? (
            <BrandTag text={"Globe Prepaid"} />
         ) : (
            <BrandTag text={"Not Globe Prepaid"} />
         );
      }
   };

   const setNativeValue = (element, value) => {
      const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
      const prototype = Object.getPrototypeOf(element);
      const prototypeValueSetter = Object.getOwnPropertyDescriptor(
         prototype,
         "value"
      ).set;

      if (valueSetter && valueSetter !== prototypeValueSetter) {
         prototypeValueSetter.call(element, value);
      } else {
         valueSetter.call(element, value);
      }
   };

   const showContactList = () => {
      window.location.hash = "viewContactList";
      const mobileNumberElement = document.getElementById("mobile-number");
      setNativeValue(mobileNumberElement, ""); // insert mobile number between double quotes
      mobileNumberElement.dispatchEvent(new Event("change", { bubbles: true }));
   };

   return (
      <div className="relative w-full">
         <input
            id="mobile-number"
            type="tel"
            className={`${
               formError || isCxsError || isExpired
                  ? "border-2 border-error-dim text-error-dim "
                  : "border-neutral-a3 text-gray-900 focus:border-primary focus:text-accent-dark focus:ring-primary "
            } block w-full rounded-lg border p-2.5 pl-4 text-sm focus:border-2 focus:outline-none`}
            placeholder="Enter Globe Prepaid mobile number"
            pattern="/^(?:\+639|639|09)\d{9}$/"
            defaultValue={mobileNumber}
            onChange={(e) => {
               setShowBrand(checkAndFormatNumber(e.target.value) !== "");
               onChange(e);
            }}
            disabled={isCheckingBrand}
            required
         />

         {isCheckingBrand ? (
            <BrandTag text={"Checking Brand"} />
         ) : (
            checkIfPrepaid({ mobileNumber, showBrand, isPrepaid })
         )}

         <span className="absolute right-0 top-2 flex cursor-pointer items-center pr-4">
            <img
               src={ContactSVG}
               alt="Account number"
               onClick={() => showContactList()}
            ></img>
         </span>

         <ErrorTag
            display={formError || isCxsError || isExpired ? "block" : "hidden"}
            formError={formError}
            isCxsError={isCxsError}
            isExpired={isExpired}
         />
      </div>
   );
};

export default MobileNumberField;
